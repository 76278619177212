import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TraceabilityTableReportComponent} from "./traceability-table-report.component";
import {TableModule} from "primeng/table";




@NgModule({
    declarations: [
        TraceabilityTableReportComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ApplicationPipesModule,
        TableModule
    ],
    exports:[
        TraceabilityTableReportComponent
    ]
})
export class TraceabilityTableReportModule { }
