<p-table

        [value]="this.tableData"
        dataKey="id"
        [paginator]="false">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="date">{{ 'TRAZABILITY.Date' | translate }}</th>
            <th pSortableColumn="time">{{ 'TRAZABILITY.Time' | translate }}</th>
            <th pSortableColumn="deliveryNote" *ngIf="type != 1 && type != 2">{{ 'TRAZABILITY.DeliveryNote' | translate }}</th>
            <th pSortableColumn="userName">{{ 'TRAZABILITY.UserName' | translate }}</th>
            <th pSortableColumn="suplier">{{ 'TRAZABILITY.Suplier' | translate }}</th>
            <th pSortableColumn="ref">{{ 'TRAZABILITY.Ref' | translate }}</th>
            <th pSortableColumn="productName">{{ 'TRAZABILITY.ProductName' | translate }}</th>
            <th pSortableColumn="batch">{{ 'TRAZABILITY.Batch' | translate }}</th>
            <th pSortableColumn="amount" *ngIf=" type != 2">{{ 'TRAZABILITY.Amount' | translate }}</th>
            <th pSortableColumn="statusName" *ngIf="type != 0 && type !== 1">{{ 'TRAZABILITY.StatusName' | translate }}</th>
            <th pSortableColumn="conservationName" *ngIf="type != 1 && type != 2">{{ 'TRAZABILITY.ConservationName' | translate }}</th>
            <th pSortableColumn="expiration" *ngIf="type != 1">
                {{ (dateSecondary ? 'TRAZABILITY.dateSecondaryExpiration' : 'TRAZABILITY.datePrimaryExpiration') | translate }}
            </th>
            <th pSortableColumn="weight" *ngIf=" type != 2">{{ 'TRAZABILITY.Weight' | translate }}</th>
            <th pSortableColumn="centerName">{{ 'TRAZABILITY.CenterName' | translate }}</th>
            <th pSortableColumn="unitName">{{ 'TRAZABILITY.UnitName' | translate }}</th>
            <th pSortableColumn="unitDestiny" *ngIf="type != 0 && type !== 2">{{ 'TRAZABILITY.UnitDestiny' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
        <tr [pSelectableRow]="data">
            <td>{{ data.date | date: 'dd/MM/yyyy' }}</td>
            <td>{{ data.time | date: 'HH:mm' }}</td>
            <td *ngIf="type != 1 && type != 2">{{ data.deliveryNote }}</td>
            <td>{{ data.userName }}</td>
            <td>{{ data.suplier }}</td>
            <td>{{ data.ref }}</td>
            <td>{{ data.productName }}</td>
            <td>{{ data.batch }}</td>
            <td *ngIf=" type != 2">{{ data.amount }}</td>
            <td *ngIf="type != 0 && type !== 1">{{ data.statusName }}</td>
            <td *ngIf="type != 1 && type != 2">{{ data.conservationName }}</td>
            <td *ngIf="type != 1">{{ dateSecondary? (data.expirationSecondary | date: 'dd/MM/yyyy HH:mm') :  (data.expiration | date: 'dd/MM/yyyy HH:mm')}}</td>
            <td *ngIf="type != 2">{{ data.transferedQuantity }}</td>
            <td>{{ data.unitName }}</td>
            <td>{{ data.centerName }}</td>
            <td *ngIf="type != 0 && type !== 2">{{ data.unitDestiny }}</td>
        </tr>
    </ng-template>
</p-table>
