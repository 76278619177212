import {Component, Input, OnInit} from '@angular/core';
import {TraceabilityGenericReportDTO} from "../../DTO/Requests/TraceabilityReportDTOS";

@Component({
  selector: 'app-traceability-table-report',
  templateUrl: './traceability-table-report.component.html',
  styleUrls: ['./traceability-table-report.component.scss']
})
export class TraceabilityTableReportComponent implements OnInit{
  units = ['Kg' , 'L' , 'g' ,'ml']
  @Input()
  public tableData: TraceabilityGenericReportDTO[];

  @Input()
  public dateSecondary: boolean = false;

  @Input()
  public type: number;
  ngOnInit(): void {
    if (this.type === 1) {
      this.tableData.forEach(item => {
        if (this.units.includes(item.transferedUnit)) {
          item.amount = '';
        } else {
          item.transferedQuantity = '';
        }
      });
    }
  }


}
