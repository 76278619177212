<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false && isLogged"
      [hidden]="!isLogged">
    <!--<mat-toolbar>{{ 'Menu' | translate }}</mat-toolbar>-->
    <mat-toolbar class="p-2">
      <img src="/assets/img/logo-testo.svg" style="max-height: 100%">
    </mat-toolbar>
    <mat-nav-list class="pt-4">
      <a *ngIf="role != 'Supplier'" mat-list-item routerLink="/centers" routerLinkActive="active">
        <mat-icon>store</mat-icon> {{ 'Centers' | translate }}
      </a>
      <a *ngIf="role != 'Supplier'" mat-list-item routerLink="/users" routerLinkActive="active">
        <mat-icon>people</mat-icon> {{ 'Users' | translate }}
      </a>
      <a *ngIf="selectedClient?.hasRecipesActivated && role != 'Supplier'" mat-list-item routerLink="/suppliers" routerLinkActive="active">
        <i class="fa-solid fa-dolly fa-fw"></i> {{ 'Suppliers' | translate }}
      </a>
      <a mat-list-item routerLink="/products" routerLinkActive="active">
        <i class="fa-solid fa-box fa-fw"></i> {{ 'Products' | translate }}
      </a>
      <a *ngIf="selectedClient?.hasRecipesActivated && role != 'Supplier'" mat-list-item routerLink="/recipes" routerLinkActive="active">
        <i class="fa-solid fa-utensils fa-fw"></i> Recetas
      </a>
      <mat-expansion-panel *ngIf="role != 'Supplier'" class="mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-icon>assessment</mat-icon> {{ 'Reports' | translate }}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="/reports/stocks" routerLinkActive="active">
            <mat-icon>inventory_2</mat-icon>  {{ 'stocks' | translate }}
          </a>
          <a mat-list-item routerLink="/reports/receptions" routerLinkActive="active">
            <mat-icon>list_alt_check</mat-icon>  {{ 'Receptions' | translate }}
          </a>
          <a mat-list-item routerLink="/reports/traceability" routerLinkActive="active">
            <mat-icon>line_axis</mat-icon>
            {{'TRAZABILITY.Trazabilidad' | translate}}
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
      <a *ngIf="selectedClient?.hasRecipesActivated && role != 'Supplier'" mat-list-item routerLink="/manteniment" routerLinkActive="active">
        <i class="fa-solid fa-wrench"></i> {{ 'EQUIPMENTS.maintenance' | translate }}
      </a>
    </mat-nav-list>

    <mat-nav-list class="bottom">
      <div class="lang-selector-wrapper">
        <div>
          {{ 'Language' | translate }}
        </div>
        <mat-form-field>
          <mat-select
          [(value)]="selectedLang"
          (selectionChange)="onLanguageChanges()">
              <mat-option value="es">Español</mat-option>
              <mat-option value="en">English</mat-option>
              <mat-option value="fr">Français</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div class="version-wrapper text-center">
        <span class="env"><span *ngIf="!env.production">{{env.name}}</span> {{env.appVersion}}</span>
      </div>
      <a mat-list-item class="center" (click)="logout()"><mat-icon>logout</mat-icon> {{ 'Logout' | translate }}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="env.name == 'dev'" class="environment-bar">
      {{ 'TEST_ENVIRONMENT' | translate }}
    </div>
    <mat-toolbar color="primary" [hidden]="!isLogged">
      <div>
          <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <span>{{ 'APP_TITLE' | translate }}</span>
      </div>

      <div>
        <mat-form-field *ngIf="clients && clients.length > 1" class="clients-selector">
          <mat-select
          [(value)]="selectedClientId"
          (selectionChange)="onClientChanges()">
            <mat-option *ngFor="let option of clients" [value]="option.id">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="clients && clients.length == 1">
          {{clients[0].name}}
        </div>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
