import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModelValidatorComponent } from './ng-model-validator/ng-model-validator.component';
import { ExpirationDateComponent } from './expiration-date/expiration-date.component';
import { TraceabilityTableReportComponent } from './traceability-table-report/traceability-table-report.component';



@NgModule({
  declarations: [
    NgModelValidatorComponent,
    ExpirationDateComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ApplicationPipesModule
  ],
  exports:[
    NgModelValidatorComponent,
    ExpirationDateComponent
  ]
})
export class ComponentsModule { }
