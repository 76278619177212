import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TraceabilityComponent} from "./traceability.component";
import {MatIconModule} from "@angular/material/icon";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {MultiSelectModule} from "primeng/multiselect";
import {MatButtonModule} from "@angular/material/button";
import {CalendarModule} from "primeng/calendar";
import {SelectButtonModule} from "primeng/selectbutton";
import {TableModule} from "primeng/table";
import {AccordionModule} from "primeng/accordion";
import {SkeletonModule} from "primeng/skeleton";
import {
    TraceabilityTableReportModule
} from "../../../components/traceability-table-report/traceability-table-report.module";



@NgModule({
    declarations: [
        TraceabilityComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        InputTextModule,
        FormsModule,
        DropdownModule,
        MultiSelectModule,
        MatButtonModule,
        CalendarModule,
        SelectButtonModule,
        ReactiveFormsModule,
        TableModule,
        AccordionModule,
        SkeletonModule,
        TraceabilityTableReportModule
    ],
})
export class TraceabilityModule { }
