import { StocksModule } from './stocks/stocks.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import {TraceabilityModule} from "./traceability/traceability.module";


@NgModule({
  imports: [
    CommonModule,
    ReportsRoutingModule,
    StocksModule,
    TraceabilityModule
  ],
  declarations: [
  ]
})
export class ReportsModule { }
